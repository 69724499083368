// AppConstant.js
export const MSG_DURATION = 5;

// Assessment Constant

export const MIN_RECORDING_DURATION = 20;
export const DEFAULT_ASSESSMENT_TYPE = "initial";
export const DEFAULT_CUSTOMER_KEY = "testkey";

// Threshold for what is considered "silence". Any audio signal with an absolute amplitude
// below this value is considered silent. In this case, 0.02 is a small value,
// meaning very low audio levels are treated as silence.
export const SILENCE_THRESHOLD = 0.02;
// Maximum duration (in seconds) of initial silence that we are willing to allow in the audio.
// If the silence exceeds this value, we assume the audio is "silent" at the start. In this case,
// it is set to 5 seconds.
export const MAX_SILENCE_DURATION = 5;
